<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-snackbar
                v-model="showPopup"
                :timeout="timeout"
                color="success"
                top
                center
                >
                {{ this.message }}
            </v-snackbar>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            class="sticky-table"
            :items="data"
            :search="search">
            <!-- <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template> -->
            <!-- <template
                v-slot:item.company_name="{item}">
                <div class="sticky-column">
                    {{ item.company_name }}
                </div>
            </template> -->
            <template v-slot:header="">
                <thead>
                <tr>
                    <th v-for="(header, index) in dataHeader" :key="index" :class="index === 0 ? 'sticky-column' : ''">
                    {{ header.text }}
                    </th>
                </tr>
                </thead>
            </template>

            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Track Failed Register Webinar 
                                </v-toolbar-title>
                            </v-row>
                            
                            <AFilterWithoutRefresh
                                :filterHeaders="dataHeader"
                                :data="data"
                                @reset="(response)=>{
                                    data = response
                                }"
                                @filter="(response)=> {
                                    data = response
                                }"
                                @refresh="fetch"/>
                                <div class="d-flex justify-start" >
                                <v-btn
                                    color="primary"
                                    style="margin-top:-68px"
                                    small
                                    @click="downloadReport"
                                    plain>
                                    Download Report
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
            <template v-slot:body="{items}">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <!-- <td>
                            {{ data.indexOf(item)+1 }}
                        </td> -->
                        <td class="sticky-column"
                        @click="copyText(item.company_name)">
                            <router-link
                                :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                                {{ item.company_name }}
                            </router-link>
                            <!-- <ACopyAbleText :text="item.company_name">
                            </ACopyAbleText> -->
                        </td>
                        <td
                        @click="copyText(item.stripe_status)">
                            <ACopyAbleText :text="item.stripe_status">
                            </ACopyAbleText>
                        </td>
                        <td
                        @click="copyText(item.period_start_date)">
                            <ACopyAbleText :text="item.period_start_date">
                            </ACopyAbleText>
                        </td>
                        <td
                        @click="copyText(item.period_end_date)">
                            <ACopyAbleText :text="item.period_end_date">
                            </ACopyAbleText>
                            
                        </td>
                        <td
                        @click="copyText(item.meeting_title)">
                            <ACopyAbleText :text="item.meeting_title" class="mt-3">
                            </ACopyAbleText>
                        </td>
                        <td
                        @click="copyText(item.start_time)">
                            <ACopyAbleText :text="item.start_time">
                            </ACopyAbleText>
                        </td>
                        <td
                        @click="copyText(item.end_time)">
                            <ACopyAbleText :text="item.end_time">
                            </ACopyAbleText>
                        </td>
                        <!-- <td>
                            <ACopyAbleText :text="item.register_created_at">
                            </ACopyAbleText>
                        </td> -->
                        <td
                        @click="copyText(item.status)">
                            <ACopyAbleText :text="item.status">
                            </ACopyAbleText>
                        </td>
                        <td
                        @click="copyText(item.renewal_status)">
                            <ACopyAbleText :text="item.renewal_status">
                            </ACopyAbleText>
                        </td>
                       
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer>
                <div class="v-data-footer custom-footer-background">
                    <!-- Custom footer content here if needed -->
                </div>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import AFilterWithoutRefresh from '../../components/common/filter/AFilterWithoutRefresh.vue';
import * as XLSX from 'xlsx';
    export default {
    components:{
    ASuccessWithoutReload,
    AFilterWithoutRefresh,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        showPopup:false,
        message:"Text copied succesfully",
        timeout:2000,
        data:[],
        dataHeader:[
            // {
            //     text:'#',
            //     value:'index',
            // },
            {
                text:'Company',
                value:'company_name'
            },
            
            {
                text:'Stripe subscription status',
                value:'stripe_status'
            },
            {
                text:'Stripe subscription start date',
                value:'period_start_date'
            },
            {
                text:'Stripe subscription end date',
                value:'period_end_date'
            },
            {
                text:'Webinar title',
                value:'meeting_title',
            },
            {
                text:'Webinar start date',
                value:'start_time'
            },
            {
                text:'Webinar end date',
                value:'end_time',
            },
            // {
            //     text:'Create date',
            //     value:'register_created_at'
            // },
            {
                text:'Reachable status',
                value:'status'
            },
            {
                text:'Renewal status',
                value:'renewal_status'
            },
            
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=== "getListOfUserFailedRegisterWebinar") {
                this.data = this.processRenewalStatus(this.$_.clone(resp.data));
                
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchUserFailedRegisterWebinar());
        },
        fetchUserFailedRegisterWebinar() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v2")+"/developer/tracker/users/webinar/failed";
            return tempApi;
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        processRenewalStatus(data) {
            data.forEach(element => {
                //Process the json
                let jsonData = JSON.parse(element.renewal);
                if (jsonData !== null) {
                    element.renewal_status = jsonData.renewal_status;
                    element.status = jsonData.status;
                    element.renewal_created_date = this.$moment(jsonData.created_at).format('YYYY-MM-DD HH:mm:ss');
                }
            });
            return data;
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.showPopup = true;
        },
        handleClosePopup() {
            this.showPopup = false;
        },
        downloadReport() {
            try{
                let downloadData = null;
                if(this.data.length > 0) {
                    downloadData = this.data.map(item=>{
                        return {
                            "Company":item.company_name,
                            "Stripe subscription status":item.stripe_status,
                            "Stripe subscription start date":item.period_start_date,
                            "Webinar title":item.meeting_title,
                            "Webinar start date":item.start_time,
                            "Webinar end date":item.end_time,
                            "Reachable status":item.status,
                            "Renewal status":item.renewal_status
                        }
                    });
                }
                else {
                    downloadData = [
                        {
                            "Company":"",
                            "Stripe subscription status":"",
                            "Stripe subscription start date":"",
                            "Webinar title":"",
                            "Webinar start date":"",
                            "Webinar end date":"",
                            "Reachable status":"",
                            "Renewal status":""
                        }
                    ]
                }
                let worksheet = XLSX.utils.json_to_sheet(downloadData);
                let workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook,worksheet,"Sheet1");
                const binaryData = XLSX.write(workbook,{bookType:'xlsx',type:'array'})
                const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','report.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            }catch(error) {
                alert(error)
            }
            // let fetchUserFailedRegisterWebinarList = this.fetchUserFailedRegisterWebinar();
            // fetchUserFailedRegisterWebinarList.url = fetchUserFailedRegisterWebinarList.url+"?download=true";
            // this.$api.fetch(fetchUserFailedRegisterWebinarList);
        }
    }
}
</script>
<style>
.sticky-table {
    overflow-x:scroll;
}
.sticky-column {
    position:sticky;
    left:0;
    z-index:1;
    background:#f8f9fa;
    border-right: 1px solid #ddd;
}
td {
    min-width:200px;
    text-align:center;
    font-family: Arial, sans-serif;
    border-right: 1px solid #ddd;
}
th {
    text-align:center!important;
    background:#f8f9fa;
    border-top:1px solid #ddd;
    border-right: 1px solid #ddd;
}

.v-data-footer {
    background-color:#f8f9fa;
}
</style>